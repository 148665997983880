var Drupal = Drupal || {};

(function ($, Drupal) {
  "use strict";

  Drupal.behaviors.footer_icons = {
    attach: function (context, settings) {
      $('.footer-tel', context).once('footer_icon').each(function () {
        $(this).prepend('<i class="fas fa-phone"></i>');
      });

      $('.footer-mail', context).once('footer_icon').each(function () {
        $(this).prepend('<i class="fas fa-envelope"></i>');
      });

      $('.footer-icons a', context).once('footer_icon').each(function () {
        const icon = $(this).data('icon');
        $(this).html('<i class="fab ' + icon + '"></i>');
      });
    }
  };
})(jQuery, Drupal);
