var Drupal = Drupal || {};

(function ($, Drupal) {
  "use strict";



  (function (window) {

    'use strict';

// class helper functions from bonzo https://github.com/ded/bonzo

    function classReg(className) {
      return new RegExp("(^|\\s+)" + className + "(\\s+|$)");
    }

// classList support for class management
// altho to be fair, the api sucks because it won't accept multiple classes at
// once
    var hasClass, addClass, removeClass;

    if ('classList' in document.documentElement) {
      hasClass = function (elem, c) {
        return elem.classList.contains(c);
      };
      addClass = function (elem, c) {
        elem.classList.add(c);
      };
      removeClass = function (elem, c) {
        elem.classList.remove(c);
      };
    }
    else {
      hasClass = function (elem, c) {
        return classReg(c).test(elem.className);
      };
      addClass = function (elem, c) {
        if (!hasClass(elem, c)) {
          elem.className = elem.className + ' ' + c;
        }
      };
      removeClass = function (elem, c) {
        elem.className = elem.className.replace(classReg(c), ' ');
      };
    }

    function toggleClass(elem, c) {
      var fn = hasClass(elem, c) ? removeClass : addClass;
      fn(elem, c);
    }

    var classie = {
      // full names
      hasClass: hasClass,
      addClass: addClass,
      removeClass: removeClass,
      toggleClass: toggleClass,
      // short names
      has: hasClass,
      add: addClass,
      remove: removeClass,
      toggle: toggleClass
    };

// transport
    if (typeof define === 'function' && define.amd) {
      // AMD
      define(classie);
    }
    else {
      // browser global
      window.classie = classie;
    }

  })(window);


  Drupal.behaviors.pagescroll = {
    attach: function (context) {
      $(context).find('.navbar-default').once('navbar', function () {

        var docElem = document.documentElement,
          header = this,
          didScroll = false,
          changeHeaderOn = 150;

        function scrollPage() {
          var sy = scrollY();
          if (sy >= changeHeaderOn) {
            classie.add(header, 'navbar-shrink');
          }
          else {
            classie.remove(header, 'navbar-shrink');
          }
          didScroll = false;
        }

        function scrollY() {
          return window.pageYOffset || docElem.scrollTop;
        }

        window.addEventListener('scroll', function (event) {
          if (!didScroll) {
            didScroll = true;
            setTimeout(scrollPage, 0);
          }
        }, false);


      });
    }
  };
})(jQuery, Drupal);

