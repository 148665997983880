/**
 * @file
 * navbar-scroll.js
 */

var Drupal = Drupal || {};

(function ($, Drupal) {
  "use strict";

  var $document = $(document);

  Drupal.behaviors.praxisfeld_navbar_scroll = {
    attach: function (context) {
      $(document).ready(function () {

        'use strict';

        if ($('#edit-tid-i18n-wrapper').length) {
          $('#edit-tid-i18n-wrapper a').click(function () {
            $('#edit-tid-i18n-wrapper a.active').removeClass('active');
            var selected = $('.bef-new-value').attr('value');
            if (selected) {
              $('div.form-item-edit-tid-i18n-' + selected + ' a').addClass('active');
            }
            else {
              $('div.form-item-edit-tid-i18n-all a').addClass('active');
            }
            $(window).scrollTop(0);
            $('#navbar').removeClass("scrollUp");
          });
        }


        var c, currentScrollTop = 0,
          navbar = $('#navbar');

        $(window).scroll(function () {
          var a = $(window).scrollTop();
          var b = navbar.height();

          currentScrollTop = a;

          if (c < currentScrollTop && a > b + b) {
            navbar.addClass("scrollUp");
          }
          else if (c > currentScrollTop && !(a <= b)) {
            navbar.removeClass("scrollUp");
          }
          c = currentScrollTop;
        });

      });

      $(window).on('resize', function () {
        function resetResponsive($element) {
          $('.views-quicksand-container .view-content')
            .width('100%')
            .height('auto')
        }

        $(window).resize(resetResponsive);
      });
    }
  };


})(jQuery, Drupal);
