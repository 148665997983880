var Drupal = Drupal || {};

(function ($, Drupal) {
  "use strict";

  Drupal.behaviors.praxisfeld_gallery = {
    attach: function (context, settings) {
      const lightbox = new FsLightbox();
      const imgs = $('.field-name-field-zusatzartikel img, .field-name-field-zusatzartikel2 img, .paragraphs-items-field-akkordeoneintr-ge img');
      lightbox.props.sources = imgs.map(function (index, el) {
        $(el).addClass('clickable').click(function () {
          lightbox.open(index);
        });
        return el.src;
      });
    }
  };
})(jQuery, Drupal);
