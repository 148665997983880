var Drupal = Drupal || {};

(function ($, Drupal) {
  "use strict";

  Drupal.behaviors.praxisfeld_accordion = {
    attach: function (context, settings) {
      $('.accordion-item', context).once('accordion').each(function () {
        var $item = $(this);
        var $title = $item.find('.accordion-title');
        var $content = $item.find('.accordion-content');

        $title.click(function () {
          $content.slideToggle(200);
          $item.toggleClass('is-active');

          $item.parent().siblings().find('.accordion-item').removeClass('is-active').find('.accordion-content').slideUp(200);
        });
      });
    }
  };
})(jQuery, Drupal);
